

const Lan = {
    selectImage1: " تصاویر شامل",
    selectImage2: " را انتخاب کنید",
    selectImageWarring: "لطفا حداقل یک تصویر را انتخاب کنید",
    confirm: "تأیید",
    requestCode: " درخواست کد تأیید",
    hub: "هاب فناوران شاپرک",
    messageCode: "  👇 کد تأیید ارسال شده به شماره موبایل خود را وارد کنید  👇",
    messageCode2: "👆🏻  نمونه پیام ارسالی  👆🏻",
    confirmCode: "کد تأیید",
    submit: "ثبت",
    deposit: "واریز",
    timeOutPay: "زمان پرداخت شما به اتمام رسید",
    cancelPay: "آیا از لغو تراکنش اطمینان دارید؟",
    inputYears: " سال را وارد کنید",
    inputMonth: " ماه را وارد کنید",
    inputOTP: " رمز دوم یا رمز پویا را وارد کنید",
    inputId: "شماره شناسایی را وارد کنید",
    inputExpireDate: " لطفا تاریخ انقضاء را وارد کنید ",
    errorCaptcha: "خطا در دریافت کپچا، لطفا به پشتیبان اطلاع دهید",
    amount: "مبلغ",
    getWay: "درگاه",
    IRT: "تومان",
    inputNationalCode: 'کدملی را درست وارد کنید',
    cvv2: " شماره شناسایی دوم",
    expireDate: "تاریخ انقضاء ",
    month: "ماه",
    year: "سال",
    OTP: " رمز دوم ",
    requestOTP: "   دریافت رمز دوم ",
    saveCard: "ذخیره اطلاعات کارت",
    cancel: "انصراف",
    pay: "پرداخت",
    cancelPayTitle: 'لغو تراکنش',
    cardNumber: "شماره کارت",
}

export default Lan